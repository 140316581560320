<template>
    <div class="m-2">
        <bread-crumbs :items="items"></bread-crumbs> 
        <v-tabs
        grow
        v-model="active_tab"
        >
            <v-tab
            v-for="tab of tabs"
            :key="tab.index"
            :to="tab.href"
            >
            {{tab.name}}
            </v-tab>
        </v-tabs>
        

    </div>
</template>

<script>
import BreadCrumbs from '@/components/breadCrumbs.vue'
export default{
    components:{BreadCrumbs},
    data() {
        return {
            active_tab:0,
            items:[
                // {
                //     text: this.$store.state.hometitle,
                //     disabled: false,
                //     to: '/home',
                // },
                // {
                //     text: 'الاعدادات',
                //     disabled: true,
                //     to: '/settings',
                // },
                {
                    text: 'اعدادات النظام',
                    disabled: true,
                    to: '/settings/system-settings',
                },
            ],
            tabs: [
                {
                    index:0,name:'اعدادات النظام',href:'/settings/system-settings'
                },
                {
                    index:1,name:'المستخدمين',href:'/settings/users-settings'
                },
                {
                    index:2,name:'الصلاحيات',href:'/settings/permissions-settings'
                }
            ]
        }
    },
    computed: {
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
    },
}
</script>